<template>
  <div>
    <big-title>
      Queremos <span>conocerte</span>.
    </big-title>

    <!-- SECCIONES -->

    <vx-card v-if="isMounted && !failed">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container">
          <vs-tab label="Queremos conocerte" @click="$router.push({ name: 'kycSolicitante' })">
          </vs-tab>
          <vs-tab label="Clasificación de persona" @click="$router.push({ name: 'kycSolicitantePersona' })">
          </vs-tab>
          <vs-tab :label="group.title" icon-pack="feather" :icon="group.icon" v-for="(group, i) in sectionGroups" :key="i">
            <div class="tab-text">
              <vx-card v-for="section in group.sections" :key="section.data.id" :title="section.data.public_name" class="mb-5">
                <p class="mb-4" v-if="section.data.public_description != null">{{section.data.public_description}}</p>
                <div class="vx-row">
                  <template v-for="f in section.data.fields">
                    <!-- Campos automaticos -->
                    <form-field
                      :ref="group.slug"
                      v-if="isAutoFormField(f)"
                      :key="f.id"
                      :f="f"
                      :dataContainer="getContainerForField(section, f)"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :evaluator="evaluateCondition" />

                    <business-profile-tins-control
                      :key="f.id"
                      :f="f"
                      ref="business_profile_tins_component"
                      v-else-if="f.fname == 'business_tins' && should_fill_person_type_3"
                      :countries="collections.countries"
                      :business_profile_id = 'resource_provider.business_profile.id' />

                    <business-representatives-control
                      :key="f.id"
                      :f="f"
                      ref="business_representatives_component"
                      v-else-if="f.fname == 'business_representatives'"
                      :business_profile_id = 'resource_provider.business_profile.id' />

                    <business-stakeholders-control
                      :key="f.id"
                      :f="f"
                      ref="business_stakeholders_component"
                      v-else-if="f.fname == 'business_stakeholders'"
                      :countries="collections.countries"
                      :business_profile_id = 'resource_provider.business_profile.id' />
                  </template>
                </div>
              </vx-card>

              <!-- Save Button -->
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveTabChanges(group.slug)">Guardar cambios</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
    <load-error v-if="failed" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import formHelper from "@mixins/formHelper";
import FormField from '@components/forms/FormField.vue'
import BusinessProfileTinsControl from '@components/supplier/profile/BusinessProfileTinsControl.vue'
import BusinessRepresentativesControl from '@components/applicant/BusinessRepresentativesControl.vue'
import BusinessStakeholdersControl from '@components/applicant/BusinessStakeholdersControl.vue'

const pfaeTabsDefs = [
  { slug: "seccion1", title: "Proveedor de recursos",  icon: null, sections:[65,66,67,68] },
];

const pmTabsDefs = [
  { slug: "seccion1", title: "Proveedor de recursos",  icon: null, sections:[71,72,73,74,75,76] },
];

const sectionsToContainers = [
  { id: 71, containers: [{db:'business_profiles', path:'user.pld_resource_provider.business_profile'}, {db:'business_profile_tins', path:'user.business.tins', arrayDefs:{}}]},
  { id: 72, containers: [{db:'addresses', path:'user.pld_resource_provider.business_profile.address'}]},
  { id: 73, containers: [{db:'bank_accounts', path:'user.pld_resource_provider.business_profile.bank_accounts', arrayDefs: {getFirst: true, onNull: 'newBankAccount'}}] },
  { id: 74, containers: [{db:'business_profile_legal_representatives', path:'user.business.legal_representatives', arrayDefs:{}}] },
  { id: 75, containers: [{db:'business_profile_corporate_structures', path:'user.pld_resource_provider.business_profile.corporate_structure'}]},
  { id: 76, containers: [{db:'business_profile_stakeholders', path:'user.business.stakeholders', arrayDefs:{}}]},
  { id: 65, containers: [{db:'personal_profiles', path:'user.pld_resource_provider.personal_profile'}] },
  { id: 66, containers: [{db:'personal_profiles', path:'user.pld_resource_provider.personal_profile'}] },
  { id: 67, containers: [{db:'addresses', path:'user.pld_resource_provider.personal_profile.address'}] },
  { id: 68, containers: [{db:'bank_accounts', path:'user.pld_resource_provider.personal_profile.bank_accounts', arrayDefs: {getFirst: true, onNull: 'newBankAccount'}}] },
];

const requiredObjects = [
  'user.pld_resource_provider',
  'user.pld_resource_provider.personal_profile',
  'user.pld_resource_provider.business_profile'
];

const arrayPaths = ['user.pld_resource_provider.personal_profile.bank_accounts','user.pld_resource_provider.business_profile.bank_accounts','user.pld_resource_provider.business_profile.tins','user.pld_resource_provider.business_profile.stakeholders'];

export default {
  mixins: [formHelper, inputHelper, dateHelper],
  data(){
    return {
      loading: false,
      isMounted: false,
      isMoral: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,
      allSections: [],
      sectionGroups: [],
      tabs: [],
      activeTab: 2,
      collections: {
        cities: [],
        states: [],
        countries: [],
        personal_occupations: [],
        business_activities: [],

        states_resource_provider_address: [],
        cities_resource_provider_address: [],
        neighborhoods_resource_provider_address: [],
        states_business_resource_provider_address: [],
        cities_business_resource_provider_address: [],
        neighborhoods_business_resource_provider_address: []
      },

      // objeto principal
      base: {
        user: {
          vulnerable_activities:[],
          pld_resource_provider: {
            personal_profile: {
              address: {
                country_id: 0
              }
            },
            business_profile: {
              address: {
                country_id: 0
              }
            },
          }
        }
      },
      // fin objeto principal

      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,

      // objetos para actualizaciones
      basePayload: {},
      validatedFields: [],
    }
  },
  components: {
    FormField,
    BusinessProfileTinsControl,
    BusinessRepresentativesControl,
    BusinessStakeholdersControl,
  },
  computed: {
    ...mapState("auth", ["user"]),
    resource_provider(){
      return this.base.user.pld_resource_provider;
    },
    resourceProviderBankAccount(){
      if (!this.isMoral) {
        return this.resource_provider.personal_profile.bank_accounts[0];
      }
      else{
        return this.resource_provider.business_profile.bank_accounts[0];
      }
    },
    should_fill_person_type_1(){
      return this.resource_provider.person_type == 1;
    },
    should_fill_person_type_2(){
      return this.resource_provider.person_type == 2;
    },
    should_fill_person_type_2_and_iban_provided(){
      if(this.resource_provider.person_type == 2)
      {
        if(this.resourceProviderBankAccount != null && (this.resourceProviderBankAccount.iban == null || this.resourceProviderBankAccount.iban == ""))
        {
          return true;
        }
      }
      return false;
    },
    should_fill_person_type_3(){
      return this.resource_provider.person_type == 3;
    },
    should_fill_person_type_0(){
      return this.resource_provider.person_type == 0;
    },
    should_fill_stock_market_listed(){
      return this.resource_provider.business_profile.corporate_structure.stock_market_listed == 0;
    },
    should_fill_stock_market_listed_or_social_capital_more_than_50(){
      return this.resource_provider.business_profile.corporate_structure.stock_market_listed == 1 || this.resource_provider.business_profile.corporate_structure.social_capital_more_than_50 == 1;
    },
    should_fill_person_type_3_and_iban_provided(){
      if(this.resource_provider.person_type == 3)
      {
        if(this.resourceProviderBankAccount != null && (this.resourceProviderBankAccount.iban == null || this.resourceProviderBankAccount.iban == ""))
        {
          return true;
        }
      }
      return false;
    },
    is_mexico_selected(){
      if (!this.isMoral) {
        return this.resource_provider.personal_profile.address.country_id == this.mexicoCountryId;
      }else{
        return this.resource_provider.business_profile.address.country_id == this.mexicoCountryId;
      }
    },
    is_mexico_not_selected(){
      if (!this.isMoral) {
        return this.resource_provider.personal_profile.address.country_id != this.mexicoCountryId;
      }else{
        return this.resource_provider.business_profile.address.country_id != this.mexicoCountryId;
      }
    }
  },
  async mounted() {
    this.loading = true;
    this.isMounted = false;
    await this.getCollections();
    await this.getApplicantData();
    this.setPersonTypeInfo();
    await this.getFormInfo();
    this.setSectionGroups();
    this.isMounted = true;
    this.loading = false;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
        this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }
  },
  methods: {
    async reloadFormInfo(){
      this.isRefreshing = true;
      await this.getApplicantData();
      this.setPersonTypeInfo();
      this.reloadSectionGroups();
      this.isRefreshing = false;
    },
    async saveTabChanges(gslug){
      console.log("saving => " + gslug);
      let result = await this.runValidations(gslug);
      if(!result){
        this.missingFieldsNotif();
      }
      else {
        this.loading = true;
        //guardar solo la informacion que se ha modificado
        this.basePayload = this.collectFormData(gslug);
        this.basePayload.id = this.base.id;
        if(this.objectIsEmpty(this.basePayload)){
          //this.notFieldsToUpdate();
          this.saveSuccessNotif();
          this.loading = false;
          return;
        }

        try {
          // ejecutar guardado
          let response = await axios.put(`/api/v1/applicant/${this.ApplicantId}/deepUpdateKyc`, this.basePayload);
          console.log(response.data);
          // solicitar la informacion actualizada del modelo
          await this.reloadFormInfo();
          this.saveSuccessNotif();
        }
        catch (error) {
          this.failedOperationNotif(null, null);
        }

        this.loading = false;
      }
    },
    async runValidations(gslug){
      // validar componentes tipo FormField
      let res1 = await this.validateFormFields(gslug);
      if(!res1){
        return false;
      }

      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      }

      // inspeccionar los campos para ejecutar validaciones especiales en campos tipo FormField
      let spValidation = await this.checkFormFieldsSpecialValidations(gslug);
      if(!spValidation){
        return false;
      }

      // inspeccionar los campos agregados directo en el componente para ejecutar validaciones especiales
      let group = this.sectionGroups.find(g => g.slug == gslug);
      let sp_failed = false;
      group.sections.forEach(s => {
        s.data.fields.filter(f => f.special_definition == 1).forEach(f => {
          // considerar los campos habilitados (visibles), con validaciones especiales
          if(this.fields[f.fname] && f.special_validation != null && f.special_validation.method != null) {
            let res = this[f.special_validation.method]();

            if(!res){
              this.errors.add({
                field: f.fname,
                msg: f.special_validation.error
              });
              this.missingFieldsNotif(f.field_name, f.special_validation.error, 10000);
              sp_failed = true;
            }
          }
        });
      });

      if(sp_failed){
        return false;
      }

      // retornar true si todas las validaciones fueron positivas
      return true;
    },
    collectFormData(gslug){
      let payload = {};
      let group = this.sectionGroups.find(g => g.slug == gslug);
      group.sections.forEach(section => {
        section.containers.forEach(cc => {
          let fields = this.validatedFields.filter(vf => vf.db == cc.db);
          if(fields.length < 1){
            return;
          }

          if(cc.path == "base"){
            fields.forEach(f => {
              payload[f.fname] = cc.container[f.fname];
            });
          }
          else {
            let obj = null;
            if(arrayPaths.includes(cc.path)){
              // se integran todos los objetos de la coleccion
              obj = this.nestedFieldValue(this.base, cc.path);
            }
            else {
              obj = {id: cc.container.id};
              fields.forEach(f => {
                obj[f.fname] = cc.container[f.fname];
              });
            }
            // ajuste para objetos tipo array
            this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
          }
        });
      });
      return payload;
    },
    async validateFormFields(refGroup){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let r = await this.$refs[refGroup][p].checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },
    async checkFormFieldsSpecialValidations(refGroup){
      let spvalid = true;
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let sp = this.$refs[refGroup][p].specialValidation();
        if(sp != null){
          let res = this[sp.method]();
          let n = this.$refs[refGroup][p].fieldName;
          if(!res){
            spvalid = false;
            this.errors.add({
              field: n,
              msg: sp.error
            });
            this.missingFieldsNotif(n, sp.error, 10000);
          }
        }
      }

      return spvalid;
    },
    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        console.log("onFieldChange => " + method)
        this[method]();
      }
    },
    /* evaluator */
    evaluateCondition(condition){
      if(condition != null && condition in this){
        let ss = this[condition];
        console.log("evaluateCondition => " + condition + ", " + ss);
        return ss;
      }
    },
    setSectionGroups(){
      this.tabs.forEach(t => {
        let group = { title: t.title, icon: t.icon, slug: t.slug };
        let sections = [];

        t.sections.forEach(ss => {
          let s = this.allSections.find(f => f.id == ss);
          if(s != null){
            let sb = { data: s, containers: this.getContainersForSection(s.id) };
            sections.push(sb);
          }
          else {
            this.warn("Section " + ss + " not found");
          }
        })
        group.sections = sections;
        this.sectionGroups.push(group);
      });
    },
    reloadSectionGroups(){
      this.sectionGroups.forEach(group => {
        group.sections.forEach(s => {
          s.containers = this.getContainersForSection(s.data.id);
        })
      });
    },
    getContainerForField(section, f){
      let c = section.containers.find(sc => sc.db == f.db_table);
      if(!c){
        this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
        return null;
      }
      else {
        return c.container;
      }
    },
    getContainersForSection(section_id){
      let c = sectionsToContainers.find(f => f.id == section_id);
      if(!c){
        this.warn("Missing containers definition for section " + section_id);
        return null;
      }
      else {
        let sectionContainers = [];
        c.containers.forEach(cc => {
          let con = this.setContainerFromDef(cc);
          sectionContainers.push({db: cc.db, path: cc.path, container: con});
        })
        return sectionContainers;
      }
    },
    setContainerFromDef(c){
      let container = this.nestedFieldValue(this.base, c.path);
      if(Array.isArray(container)){
        let ac = null;
        if(c.arrayDefs){
          if(c.arrayDefs.getFirst == true){
            ac = container[0];
          }
          else if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval](container);
          }
        }
        if(!ac){
          if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
          else { ac = {} }
          container.push(ac);
        }
        return ac;
      }
      else {
        return container;
      }
    },
    async getApplicantData(){
      try{
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v1/applicant/${this.ApplicantId}?${params}`);
        this.base = response.data;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getFormInfo(){
      try{
        if (this.isMoral) {
          await this.getSectionInfo(71);
          await this.getSectionInfo(72);
          await this.getSectionInfo(73);
          await this.getSectionInfo(74);
          await this.getSectionInfo(75);
          await this.getSectionInfo(76);
        }else{
          await this.getSectionInfo(65);
          await this.getSectionInfo(66);
          await this.getSectionInfo(67);
          await this.getSectionInfo(68);
        }
        this.allSections.forEach(s => {
          s.fields.forEach(f => {
            this.formFieldSetter(f, this, true);
          });
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getSectionInfo(id){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + id);
        this.allSections.push(response.data.section);
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    setPersonTypeInfo(){
      if (this.resource_provider.person_type == 1 || this.resource_provider.person_type == 2) {
        this.isMoral = false;
      }else{
        this.isMoral = true;
      }
      this.tabs = this.isMoral ? pmTabsDefs : pfaeTabsDefs;
      if(this.resourceProviderBankAccount != null){
        this.resourceProviderBankAccount.bank_name = this.resourceProviderBankAccount.bank.name;
        this.resourceProviderBankAccount.bank_country_id = this.resourceProviderBankAccount.bank.country_id;
      }
    },
    async getCollections(){
      try {
        let response1 = await axios.get('/api/register/countries');
        this.collections.countries = response1.data;

        let response2 = await axios.get('/api/register/states');
        this.collections.states = response2.data;

        let response3 = await axios.get('/api/v1/forms/getPersonalOccupationsList')
        this.collections.personal_occupations = response3.data;

        let response4 = await axios.get('/api/v1/forms/getBusinessActivities')
        this.collections.business_activities = response4.data;
      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    /* on_change methods */
    async reload_personal_cities(){
      try {
        let sid = null;
        if (this.isMoral) {
          sid = this.resource_provider.business_profile.address.state_id;
        }
        else{
          sid = this.resource_provider.personal_profile.address.state_id;
        }
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if (this.isMoral) {
          if(this.isMounted && !this.isRefreshing) this.resource_provider.business_profile.address.city_id = null;
        }
        else{
          if(this.isMounted && !this.isRefreshing) this.resource_provider.personal_profile.address.city_id = null;
        }
      } catch (e) {
        this.warn(e);
      }
    },
    personal_curp_conditions_changed(){
      let c = this.resource_provider.personal_profile.curp;
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);

        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          this.resource_provider.personal_profile.birth_date = null;
        }
        else{
          this.resource_provider.personal_profile.birth_date = date;
        }
      }
      else{
        this.resource_provider.personal_profile.birth_date = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          this.resource_provider.personal_profile.gender = 1;
        }
        else if(g == "M"){
          this.resource_provider.personal_profile.gender = 2;
        }
        else{
          this.resource_provider.personal_profile.gender = null;
        }
      }
      else{
        this.resource_provider.personal_profile.gender = null;
      }

      if(c.length >= 13){
        let code = c.slice(11, 13);
        let state = this.collections.states.find(f => f.renapo_code == code)
        if(state != null){
          this.resource_provider.personal_profile.birth_state = state.id;
        }
      }
      else{
        this.resource_provider.personal_profile.birth_state = null;
      }
    },
    newBankAccount(){
      return {id: null,iban: null, account: null,routing_number: null};
    },
    validate_business_profile_tins(){
      if(this.$refs.business_profile_tins_component != null){
        return this.$refs.business_profile_tins_component.validate();
      }
      else {
        return true;
      }
    },
    validate_business_representatives(){
      if(this.$refs.business_representatives_component != null){
        return this.$refs.business_representatives_component.validate();
      }
      else {
        return true;
      }
    },
    validate_business_stakeholders(){
      if(this.$refs.business_stakeholders_component != null){
        return this.$refs.business_stakeholders_component.validate();
      }
      else {
        return true;
      }
    },
    validate_user_birthdate(){
      let years = this.calculateAgeFromDate(this.resource_provider.personal_profile.birth_date);
      return years >= 18;
    },
    verify_zip_code_resource_provider_address() {
      this.verifyZipCode(this.base.user.pld_resource_provider.personal_profile.address, {
        zipCodeVariable: 'zip_code_resource_provider_address',
        statesArray: 'states_resource_provider_address',
        citiesArray: 'cities_resource_provider_address',
        neighborhoodsArray: 'neighborhoods_resource_provider_address'
      });
    },
    set_zip_code_values_resource_provider_address() {
      this.setZipCodeAddressValues(this.base.user.pld_resource_provider.personal_profile.address_id, this.base.user.pld_resource_provider.personal_profile.address, {
        zipCodeVariable: 'zip_code_resource_provider_address',
        statesArray: 'states_resource_provider_address',
        citiesArray: 'cities_resource_provider_address',
        neighborhoodsArray: 'neighborhoods_resource_provider_address'
      });
    },

    verify_zip_code_business_resource_provider_address() {
      this.verifyZipCode(this.base.user.pld_resource_provider.business_profile.address, {
        zipCodeVariable: 'zip_code_business_resource_provider_address',
        statesArray: 'states_business_resource_provider_address',
        citiesArray: 'cities_business_resource_provider_address',
        neighborhoodsArray: 'neighborhoods_business_resource_provider_address'
      });
    },
    set_zip_code_values_business_resource_provider_address() {
      this.setZipCodeAddressValues(this.base.user.pld_resource_provider.business_profile.address_id, this.base.user.pld_resource_provider.business_profile.address, {
        zipCodeVariable: 'zip_code_business_resource_provider_address',
        statesArray: 'states_business_resource_provider_address',
        citiesArray: 'cities_business_resource_provider_address',
        neighborhoodsArray: 'neighborhoods_business_resource_provider_address'
      });
    },
  }
}
</script>